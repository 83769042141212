import dayjs from "dayjs";

export const DEFAULT_TEST_CASE = [
  {
    title: "Get profile",
    isPassed: false,
    error: "",
  },
  {
    title: "Get profile with no username exist",
    isPassed: false,
    error: "",
  },
  {
    title: "Placebet",
    isPassed: false,
    error: "",
  },
  {
    title: "Test bet cancel",
    isPassed: false,
    error: "",
  },
  {
    title: "Test bet win",
    isPassed: false,
    error: "",
  },
  {
    title: "Test bet half win",
    isPassed: false,
    error: "",
  },
  {
    title: "Test bet half lose",
    isPassed: false,
    error: "",
  },
  {
    title: "Test bet lose",
    isPassed: false,
    error: "",
  },
  {
    title: "Test bet draw",
    isPassed: false,
    error: "",
  },
];

export const DEFAULT_DATE_PICKER = {
  startDate: dayjs().startOf("month").toDate(),
  endDate: dayjs().endOf("month").toDate(),
};
