import { Button, Input } from "@material-tailwind/react";

import { useForm } from "react-hook-form";

const CreateForm = () => {
  const { register } = useForm();
  return (
    <div>
      <form className="flex flex-col gap-y-3">
        <Input
          className="bg-white"
          crossOrigin=""
          label="Username"
          {...register("username")}
        />
        <Input
          className="bg-white"
          crossOrigin=""
          label="Password"
          {...register("password")}
        />

        <Button type="submit" color="green">
          Create
        </Button>
      </form>
    </div>
  );
};

export default CreateForm;
