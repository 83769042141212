import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import ReactJson from "react-json-view";

export const COLUMNS: ColumnDef<any>[] = [
  {
    accessorKey: "createDate",
    header: "Date",
    cell: (data) => {
      const dateString = data.getValue() as string;
      const date = dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");

      return <div className="text-center text-sm w-32">{date}</div>;
    },
  },
  {
    accessorKey: "httpHeaders",
    header: "httpHeaders",
    cell: (data) => {
      const JSONString = data.getValue<string>();

      return (
        <>
          <div className="w-96">
            <div className="text-xs">
              <span className="font-bold">Method : </span>
              <span>
                {data.row.original.httpMethod
                  .replace('"', "")
                  .replace('"', "")
                  .toUpperCase()}
              </span>
            </div>
            <div className="text-xs">
              <span className="font-bold">URL : </span>
              <span className="break-words">{data.row.original.endPoint}</span>
            </div>
            <ReactJson
              name={false}
              displayDataTypes={false}
              collapsed={data.row.index === 0 ? 1 : true}
              src={JSONString ? JSON.parse(JSONString) : {}}
            />
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "typeLogs",
    header: "Type",
    cell: (data) => {
      const type = data.getValue() as string;

      return (
        <div className="text-center text-sm w-20 font-bold">
          {type[0].toUpperCase()}
          {type.slice(1)}
        </div>
      );
    },
  },
  {
    accessorKey: "payload",
    header: "Payload",
    cell: (data) => {
      const JSONString = data.getValue<string>();

      return (
        <div className="w-80">
          {!!JSONString ? (
            <ReactJson
              name={false}
              displayDataTypes={false}
              collapsed={data.row.index === 0 ? 1 : true}
              src={JSONString ? JSON.parse(JSONString) : {}}
            />
          ) : (
            <div className="text-center">-</div>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "respone",
    header: "Response",
    cell: (data) => {
      const JSONString = data.getValue<string>();

      return (
        <div className="w-56">
          <div className="text-xs font-bold">
            Status : {data.row.original.httpStatus}
          </div>
          <ReactJson
            name={false}
            displayDataTypes={false}
            collapsed={data.row.index === 0 ? 1 : true}
            src={JSONString ? JSON.parse(JSONString) : {}}
          />
        </div>
      );
    },
  },
];
