import { ColumnDef } from "@tanstack/react-table";
import classnames from "classnames";

export const COLUMNS: ColumnDef<any>[] = [
  {
    accessorKey: "_id",
    header: "Name",
    cell: (data) => {
      const name = data.getValue<string>();
      return (
        <div className="text-sm text-center">
          <b>{name}</b>
        </div>
      );
    },
  },
  {
    accessorKey: "total",
    header: "Total <THB>",
    cell: (data) => {
      const total = data.getValue<string>();
      return (
        <div className="text-sm text-center">
          <b
            className={classnames({
              "text-red-700": Number(total) < 0,
              "text-green-700": Number(total) > 0,
            })}
          >
            {Number(total) > 0 && "+"}{Number(Number(total).toFixed(2)).toLocaleString("en-US")}
          </b>
        </div>
      );
    },
  },
];
