import { StyledTable } from "./styled";
import { COLUMNS } from "./config";
import Table from "../Table";
import { FC } from "react";

const LogsTable: FC<{ data: any[] }> = ({ data = [] }) => {
  return (
    <StyledTable>
      <Table<any> data={data} columns={COLUMNS} />
    </StyledTable>
  );
};

export default LogsTable;
