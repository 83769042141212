import { useQuery } from "@tanstack/react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { Breadcrumbs } from "@material-tailwind/react";
import dayjs from "dayjs";
import qs from "qs";

import service from "../../service";
import useDatePicker from "../../hook/useDatePicker";
import useFocusUser from "./useFocusUser";
import WinloseTable from "../../Component/WinloseTable";
import { memo, NamedExoticComponent, useCallback } from "react";

export type FooterProps = {
  betAmount: number;
  collected: number;
  remaining: number;
  sendToUpline: number;
  tickets: number;
  winLose: number;
};

const Footer: NamedExoticComponent<FooterProps> = memo(
  ({ betAmount, collected, remaining, sendToUpline, tickets, winLose }) => {
    return (
      <tr>
        <th colSpan={3}>Total</th>
        <th>{tickets && Number(tickets.toFixed(2)).toLocaleString("en-US")}</th>
        <th>
          {betAmount && Number(betAmount.toFixed(2)).toLocaleString("en-US")}
        </th>
        <th>{winLose && Number(winLose.toFixed(2)).toLocaleString("en-US")}</th>
        <th>
          {remaining && Number(remaining.toFixed(2)).toLocaleString("en-US")}
        </th>
        <th>
          {collected && Number(collected.toFixed(2)).toLocaleString("en-US")}
        </th>
        <th>
          {sendToUpline &&
            Number(sendToUpline.toFixed(2)).toLocaleString("en-US")}
        </th>
      </tr>
    );
  }
);

const Report = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { value, handleChange } = useDatePicker(
    searchParams.get("startDate")
      ? {
          startDate: dayjs(searchParams.get("startDate"), "DD-MM-YYYY")
            .startOf("day")
            .toDate(),
          endDate: dayjs(searchParams.get("endDate"), "DD-MM-YYYY")
            .startOf("day")
            .toDate(),
        }
      : undefined
  );

  const { data: focusUser, onChange: onChangeFocusUser } = useFocusUser();

  const onClickMember = useCallback(
    (member: string) => {
      const queryString = qs.stringify({
        username: member,
        startDate: dayjs(value.startDate).format("DD-MM-YYYY"),
        endDate: dayjs(value.endDate).format("DD-MM-YYYY"),
        dateField: "settleDate",
      });
      navigate(`/ticket?${queryString}`);
    },
    [navigate, value.endDate, value.startDate]
  );

  const winLose = useQuery({
    queryFn: () =>
      service.getWinLose({
        startDate: value.startDate.toISOString(),
        endDate: value.endDate.toISOString(),
        username: focusUser,
      }),
    queryKey: [
      "winLose",
      dayjs(value.startDate).format("DD-MM-YYYY"),
      dayjs(value.endDate).format("DD-MM-YYYY"),
      focusUser,
    ],
    onSuccess: () => {
      const queryString = qs.stringify({
        username: focusUser,
        startDate: dayjs(value.startDate).format("DD-MM-YYYY"),
        endDate: dayjs(value.endDate).format("DD-MM-YYYY"),
      });

      setSearchParams(queryString);
    },
  });

  return (
    <div>
      {winLose.data?.parentList && (
        <div style={{ marginLeft: -10 }}>
          <Breadcrumbs>
            {winLose.data?.parentList.map((parent: { username: string }) => (
              <b
                key={parent.username}
                onClick={() => {
                  onChangeFocusUser(parent.username);
                }}
              >
                {parent.username}
              </b>
            ))}
          </Breadcrumbs>
        </div>
      )}

      <Datepicker
        primaryColor="green"
        value={value}
        onChange={(value) => {
          handleChange(value);
        }}
        showShortcuts={true}
        separator="to"
        displayFormat={"DD-MM-YYYY"}
        maxDate={dayjs().endOf("month").toDate()}
      />

      <WinloseTable
        Footer={
          <Footer
            betAmount={winLose.data?.total?.betAmount || 0}
            collected={winLose.data?.total?.collected || 0}
            remaining={winLose.data?.total?.remaining || 0}
            sendToUpline={winLose.data?.total?.sendToUpline || 0}
            tickets={winLose.data?.total?.tickets || 0}
            winLose={winLose.data?.total?.winLose || 0}
          />
        }
        isLoading={winLose.isLoading}
        data={winLose.data?.data || []}
        context={{ onChangeFocusUser, focusUser, onClickMember }}
      />
    </div>
  );
};

export default Report;
