import {
  Input,
  Typography,
  Button,
  Card,
  CardBody,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import service, { UpdateSeamlessParam } from "../../service";
import Loading from "../../Component/Loading";

const Webhook = () => {
  const naviagete = useNavigate();
  const queryClient = useQueryClient();
  const profile = useQuery({
    queryFn: service.getProfile,
    queryKey: ["profile"],
  });

  const seamless = useQuery({
    queryFn: service.getSeamless,
    queryKey: ["seamless"],
  });

  const updateSeamless = useMutation({
    mutationFn: (payload: UpdateSeamlessParam) =>
      service.updateSeamless(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["seamless"] });
    },
  });

  const { register, handleSubmit } = useForm();

  return (
    <Card className="w-full">
      <CardBody>
        <form
          className="w-full"
          onSubmit={handleSubmit(async (data) => {
            await updateSeamless.mutateAsync({
              agentId: profile.data._id,
              hookProfile: data.getProfile,
              hookTransfer: data.fundTransfer,
            });
            alert("Success");
          })}
        >
          <div className="flex flex-col gap-y-5 mb-5">
            <div>
              <Typography variant="h6" className="mb-2">
                GetProfile
              </Typography>
              {seamless.isLoading ? (
                <Loading />
              ) : (
                <Input
                  className="bg-white !border-t-blue-gray-200 focus:!border-t-gray-900"
                  crossOrigin=""
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  containerProps={{
                    className: "min-w-0",
                  }}
                  placeholder="Webhook URL of GetProfile"
                  defaultValue={seamless.data?.hookProfile || ""}
                  {...register("getProfile")}
                />
              )}
            </div>
            <div>
              <Typography variant="h6" className="mb-2">
                FundTransfer
              </Typography>
              {seamless.isLoading ? (
                <Loading />
              ) : (
                <Input
                  className="bg-white !border-t-blue-gray-200 focus:!border-t-gray-900"
                  crossOrigin=""
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  containerProps={{
                    className: "min-w-0",
                  }}
                  placeholder="Webhook URL of FundTransfer"
                  defaultValue={seamless.data?.hookTransfer || ""}
                  {...register("fundTransfer")}
                />
              )}
            </div>
          </div>
          <div className="flex gap-x-5">
            <Button type="submit" color="green">
              อัพเดต
            </Button>
            <Button
              disabled={!seamless.data?.hookTransfer}
              type="button"
              color="blue"
              onClick={() => {
                naviagete("/test");
              }}
            >
              Test Webhook
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default Webhook;
