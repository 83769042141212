import { StyledTable } from "./styled";
import { COLUMNS } from "./config";
import Table from "../Table";
import { FC } from "react";
import classnames from "classnames";

const ShareTable: FC<{ data: any[]; total?: number }> = ({
  data = [],
  total = 0,
}) => {
  return (
    <StyledTable>
      <Table<any>
        data={data}
        columns={COLUMNS}
        Footer={
          <tr className="text-center border-t-4 border-teal-300 font-bold text-lg border-dashed">
            <td>Share's profit</td>
            <td
              className={classnames({
                "text-red-700": Number(total) < 0,
                "text-green-700": Number(total) > 0,
              })}
            >
              {Number(total) > 0 && "+"}
              {Number(Number(total).toFixed(2)).toLocaleString("en-US")}
            </td>
          </tr>
        }
      />
    </StyledTable>
  );
};

export default ShareTable;
