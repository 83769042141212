import { ColumnDef, CellContext } from "@tanstack/react-table";

const DisplayNumberCell = (data: CellContext<any, unknown>) => {
  const value = data.getValue<number>() || 0;
  return (
    <div className="text-right">
      {Number(Number(value).toFixed(2)).toLocaleString("en-US")}
    </div>
  );
};

export const MAP_POSITION: Record<string, string> = {
  SHARE_HOLDER: "Shareholder",
  AGENT_NONE_API: "Agent",
  AGENT: "Agent",
  MEMBER: "Member",
  COMPANY: "Company",
};

export const COLUMNS: ColumnDef<any>[] = [
  {
    accessorKey: "no",
    header: "No",
    cell: (data) => {
      const value = data.getValue<number>();
      return <div className="text-center">{value}</div>;
    },
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: (data) => {
      const value = data.getValue<string>();
      const isMember = data.row.original.position === "MEMBER";

      return (
        <div
          className="text-center cursor-pointer font-bold"
          onClick={() => {
            if (isMember) {
              (data as any)?.onClickMember(value);
              return;
            }
            (data as any)?.onChangeFocusUser(value);
          }}
        >
          {isMember ? `${value}`.replace((data as any).focusUser, "") : value}
        </div>
      );
    },
  },
  {
    accessorKey: "position",
    header: "Position",
    cell: (data) => {
      const value = data.getValue<string>();
      return (
        <div className="text-center">{MAP_POSITION?.[value] || value}</div>
      );
    },
  },
  {
    accessorKey: "tickets",
    header: "Ticket",
    cell: DisplayNumberCell,
  },
  {
    accessorKey: "betAmount",
    header: "Bet amount",
    cell: DisplayNumberCell,
  },
  {
    accessorKey: "winLose",
    header: "Win lose 100%",
    cell: DisplayNumberCell,
  },
  {
    accessorKey: "remaining",
    header: "Member",
    cell: DisplayNumberCell,
  },
  {
    accessorKey: "collected",
    header: "Agent",
    cell: DisplayNumberCell,
  },
  {
    accessorKey: "sendToUpline",
    header: "Company",
    cell: DisplayNumberCell,
  },
];
