import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  Row,
} from "@tanstack/react-table";
import { Fragment, ReactElement } from "react";
import Loading from "../Loading";

export type TableProps<TData> = {
  data?: TData[];
  isLoading?: boolean;
  columns: ColumnDef<TData>[];
  placeholder?: string;
  renderSubComponent?: (props: { row: Row<TData> }) => React.ReactElement;
  context?: Record<string, any>;
};

function Table<T>({
  isLoading = false,
  data = [],
  columns,
  renderSubComponent,
  placeholder,
  Footer,
  context = {},
}: TableProps<T> & { Footer?: ReactElement }) {
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const rows = table.getRowModel().rows;

  return (
    <table className="w-full">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        typeof header.column.columnDef.header === "string"
                          ? header.column.columnDef.header
                          : header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="align-top">
        {isLoading && (
          <tr>
            <td colSpan={10} className="text-center text-lg">
              <Loading size={30} className="m-auto block loading" />
            </td>
          </tr>
        )}

        {!rows?.length && !isLoading ? (
          <tr>
            <td colSpan={12} className="text-center">
              {placeholder || "No competition"}
            </td>
          </tr>
        ) : (
          rows.map((row) => {
            return (
              <Fragment key={row.id}>
                {renderSubComponent?.({ row })}

                <tr>
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, {
                          ...cell.getContext(),
                          ...context,
                        })}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })
        )}
      </tbody>
      <tfoot>
        {Footer ||
          table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
      </tfoot>
    </table>
  );
}

export default Table;
