import {
  Input,
  Typography,
  Button,
  Card,
  CardBody,
  Collapse,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import classnames from "classnames";
import { produce } from "immer";
import Loading from "../../Component/Loading";
import LogsTable from "../../Component/LogsTable";
import service from "../../service";
import dayjs from "dayjs";

export type CaseId = string;
export type CaseData = {
  logs: any[];
  title: string;
  isPassed: boolean;
  error: string;
  updateDate?: string;
  by?: string;
  id?: string;
  _id?: string;
};
export type TestCaseResult = {
  caseId: CaseId;
  result: {
    isPassed: boolean;
    error: string;
    updateDate: string;
    by: string;
    logs: any[];
  };
};
export type TestCaseParam = { username: string; caseId: string; title: string };

const TestWebhook = () => {
  const { register, handleSubmit } = useForm();
  const queryClient = useQueryClient();
  const seamless = useQuery({
    queryFn: service.getSeamless,
    queryKey: ["seamless"],
  });

  const [open, setOpen] = useState<number>();

  const handleOpen = (value: number) =>
    setOpen(open === value ? undefined : value);

  const onSuccess = async (data: TestCaseResult) => {
    const newSeamless = produce(seamless.data, (draft: any) => {
      draft.testCases[data.caseId] = {
        ...draft.testCases[data.caseId],
        isPassed: data.result.isPassed,
        error: data.result.error,
        updateDate: data.result.updateDate,
        by: data.result.by,
        logs: data.result.logs,
      };
    });
    queryClient.setQueryData(["seamless"], newSeamless);
  };

  const mutationFn = ({ username, caseId, title }: TestCaseParam) =>
    service.webhookHealthCheck({
      username,
      caseId,
      title,
    });

  const testing0 = useMutation({
    mutationFn,
    onSuccess,
  });

  const testing1 = useMutation({
    mutationFn,
    onSuccess,
  });

  const testing2 = useMutation({
    mutationFn,
    onSuccess,
  });

  const testing3 = useMutation({
    mutationFn,
    onSuccess,
  });

  const testing4 = useMutation({
    mutationFn,
    onSuccess,
  });

  const testing5 = useMutation({
    mutationFn,
    onSuccess,
  });

  const testing6 = useMutation({
    mutationFn,
    onSuccess,
  });

  const testing7 = useMutation({
    mutationFn,
    onSuccess,
  });

  const testing8 = useMutation({
    mutationFn,
    onSuccess,
  });

  const mapLoading = [
    testing0.isLoading,
    testing1.isLoading,
    testing2.isLoading,
    testing3.isLoading,
    testing4.isLoading,
    testing5.isLoading,
    testing6.isLoading,
    testing7.isLoading,
    testing8.isLoading,
  ];

  return (
    <div className="my-3">
      <Card className="">
        <CardBody className="flex gap-5 flex-col">
          <section>
            <form
              className="flex flex-col gap-y-4"
              onSubmit={handleSubmit(async (data) => {
                const data0 = await testing0.mutateAsync({
                  username: data.username,
                  title: "Get profile",
                  caseId: "0",
                });

                if (!data0.result.isPassed) {
                  return data0.result.error;
                }

                const data1 = await testing1.mutateAsync({
                  username: data.username,
                  title: "Get profile with no username exist",
                  caseId: "1",
                });

                if (!data1.result.isPassed) {
                  return data1.result.error;
                }

                const data2 = await testing2.mutateAsync({
                  username: data.username,
                  title: "Placebet",
                  caseId: "2",
                });

                if (!data2.result.isPassed) {
                  return data2.result.error;
                }

                const data3 = await testing3.mutateAsync({
                  username: data.username,
                  title: "Test bet cancel",
                  caseId: "3",
                });

                if (!data3.result.isPassed) {
                  return data3.result.error;
                }

                const data4 = await testing4.mutateAsync({
                  username: data.username,
                  title: "Test bet win",
                  caseId: "4",
                });

                if (!data4.result.isPassed) {
                  return data4.result.error;
                }

                const data5 = await testing5.mutateAsync({
                  username: data.username,
                  title: "Test bet half win",
                  caseId: "5",
                });

                if (!data5.result.isPassed) {
                  return data5.result.error;
                }

                const data6 = await testing6.mutateAsync({
                  username: data.username,
                  title: "Test bet half lose",
                  caseId: "6",
                });

                if (!data6.result.isPassed) {
                  return data6.result.error;
                }

                const data7 = await testing7.mutateAsync({
                  username: data.username,
                  title: "Test bet lose",
                  caseId: "7",
                });

                if (!data7.result.isPassed) {
                  return data7.result.error;
                }
                const data8 = await testing8.mutateAsync({
                  username: data.username,
                  title: "Test bet draw",
                  caseId: "8",
                });

                if (!data8.result.isPassed) {
                  return data8.result.error;
                }
              })}
            >
              <div>
                <Typography variant="h6">Username for testing</Typography>
                {seamless.isLoading ? (
                  <Loading className="mx-auto my-10" size={60} />
                ) : (
                  <Input
                    className="bg-white !border-t-blue-gray-200 focus:!border-t-gray-900"
                    crossOrigin=""
                    placeholder=""
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    defaultValue={seamless.data?.testCases?.[0]?.by || ""}
                    containerProps={{
                      className: "min-w-0",
                    }}
                    {...register("username")}
                  />
                )}
              </div>

              <Button disabled={seamless.isLoading} color="green" type="submit">
                Test
              </Button>
            </form>
          </section>

          <section>
            <Typography variant="h6" className="mb-5">
              Result
            </Typography>
            {seamless.isLoading ? (
              <Loading className="mx-auto my-10" size={60} />
            ) : (
              <ul className="flex flex-col">
                {seamless.data?.testCases?.map(
                  (caseTest: CaseData, index: number) => {
                    return (
                      <li key={caseTest.id}>
                        <div>
                          <div className="flex justify-between w-full">
                            <div>
                              <Typography
                                variant="h6"
                                className={classnames("flex", {
                                  "text-red-700": !caseTest.isPassed,
                                  "text-green-700": caseTest.isPassed,
                                })}
                              >
                                {mapLoading[index] ? (
                                  <Loading size={18} />
                                ) : caseTest.isPassed ? (
                                  <CheckBadgeIcon className="h-5 w-5 mr-1" />
                                ) : (
                                  <XCircleIcon className="h-5 w-5 mr-1" />
                                )}{" "}
                                {caseTest.title}
                              </Typography>
                              {!!caseTest.updateDate && (
                                <p className="text-xs ml-6">
                                  {dayjs(caseTest.updateDate).format(
                                    "DD-MM-YYYY HH:mm:ss"
                                  )}
                                </p>
                              )}
                            </div>
                            <button
                              className="flex items-center text-blue-600 hover:underline"
                              onClick={() => handleOpen(index)}
                            >
                              <span className="text-xs">See the last logging</span>
                              <ChevronDownIcon
                                strokeWidth={3}
                                className={classnames("h-3 w-5", {
                                  "rotate-180": open === index,
                                  "rotate-0": open !== index,
                                })}
                              />
                            </button>
                          </div>

                          {!!caseTest.error && (
                            <div
                              className={classnames(
                                "flex items-center gap-x-2",
                                {
                                  "text-red-700": !caseTest.isPassed,
                                  "text-green-700": caseTest.isPassed,
                                }
                              )}
                            >
                              <b className="text-sm underline">
                                Error message:
                              </b>
                              <p className="text-sm">{caseTest.error}</p>
                            </div>
                          )}
                        </div>
                        <Collapse open={open === index} className="my-5">
                          {!!caseTest.logs && (
                            <div className="overflow-x-scroll w-full">
                              <LogsTable data={caseTest.logs} />
                            </div>
                          )}
                        </Collapse>
                      </li>
                    );
                  }
                )}
              </ul>
            )}
          </section>
        </CardBody>
      </Card>
    </div>
  );
};

export default TestWebhook;
