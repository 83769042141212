import {
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import {
  PresentationChartBarIcon,
  ChartBarIcon,
  NewspaperIcon,
  UsersIcon,
  InboxIcon,
  PowerIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

export enum Accord {
  developer = "DEVELOPER",
}

const Sidebar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<Accord>();
  const queryClient = useQueryClient();

  const logout = async () => {
    window?.localStorage.removeItem("auth");
    await queryClient.invalidateQueries({ queryKey: ["profile"] });
    navigate("/login");
  };

  const handleOpen = (value: Accord) => {
    setOpen(value === open ? undefined : value);
  };
  return (
    <div className="h-[calc(100vh-2rem)] w-70 p-4 fixed top-16 Z-[100] bg-white border-r border-blue-gray-100">
      <List>
        <ListItem
          onClick={() => {
            navigate("/");
          }}
        >
          <ListItemPrefix>
            <PresentationChartBarIcon className="h-5 w-5" />
          </ListItemPrefix>
          Overview
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/member");
          }}
        >
          <ListItemPrefix>
            <UsersIcon className="h-5 w-5" />
          </ListItemPrefix>
          Organization
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/ticket");
          }}
        >
          <ListItemPrefix>
            <NewspaperIcon className="h-5 w-5" />
          </ListItemPrefix>
          Ticket
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/report");
          }}
        >
          <ListItemPrefix>
            <ChartBarIcon className="h-5 w-5" />
          </ListItemPrefix>
          Report
        </ListItem>
        <Accordion
          open={open === Accord.developer}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === Accord.developer ? "rotate-180" : ""
              }`}
            />
          }
        >
          <AccordionHeader
            onClick={() => handleOpen(Accord.developer)}
            className="border-b-0 py-0 text-md font-normal text-left"
          >
            <ListItem>
              <ListItemPrefix>
                <InboxIcon className="h-5 w-5" />
              </ListItemPrefix>
              For Developer
            </ListItem>
          </AccordionHeader>
          <AccordionBody className="py-1">
            <List className="p-0">
              <ListItem
                onClick={() => {
                  navigate("/webhook");
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Webhook
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/test");
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Test
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/logs");
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Logs
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>

        {/* <ListItem>
          <ListItemPrefix>
            <UserCircleIcon className="h-5 w-5" />
          </ListItemPrefix>
          Profile
        </ListItem>
        <ListItem>
          <ListItemPrefix>
            <Cog6ToothIcon className="h-5 w-5" />
          </ListItemPrefix>
          Settings
        </ListItem> */}
        <ListItem
          onClick={() => {
            logout();
          }}
        >
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          Log Out
        </ListItem>
      </List>
    </div>
  );
};

export default Sidebar;
