import dayjs from "dayjs";
import { useState, useCallback, useEffect } from "react";
import { DateValueType } from "react-tailwindcss-datepicker";
import { DEFAULT_DATE_PICKER } from "../constant";
import { useSearchParams } from "react-router-dom";

const useDatePicker = (defaultDate?: { startDate: Date; endDate: Date }) => {
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState(defaultDate ?? DEFAULT_DATE_PICKER);
  const handleChange = useCallback((newValue: DateValueType) => {
    if (newValue && newValue.startDate && newValue.endDate) {
      setValue({
        startDate: dayjs(newValue.startDate).startOf("day").toDate(),
        endDate: dayjs(newValue.endDate).endOf("day").toDate(),
      });
    } else {
      setValue(DEFAULT_DATE_PICKER);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("startDate")) {
      handleChange({
        startDate: dayjs(searchParams.get("startDate"), "DD-MM-YYYY")
          .startOf("day")
          .toDate(),
        endDate: dayjs(searchParams.get("endDate"), "DD-MM-YYYY")
          .startOf("day")
          .toDate(),
      });
    }
  }, [handleChange, searchParams]);

  return {
    handleChange,
    value,
  };
};

export default useDatePicker;
