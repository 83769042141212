import { Typography } from "@material-tailwind/react";
import { FC, PropsWithChildren } from "react";
import { ReactElement } from "react";

export type PageLayoutProps = {
  title: string;
  ActionButton?: ReactElement;
};

const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({
  title,
  children,
  ActionButton,
}) => {
  return (
    <div className="px-4 py-5 w-full h-full">
      <div className="mb-4 pb-1 flex items-center justify-between">
        <Typography
          className="border-b-green-500 border-b-2 inline-block"
          variant="h3"
        >
          {title}
        </Typography>
        {ActionButton}
      </div>

      {children}
    </div>
  );
};

export default PageLayout;
