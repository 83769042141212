import { ColumnDef, CellContext } from "@tanstack/react-table";
import classNames from "classnames";
import BettingTeamName from "../BettingTeamName";
import dayjs from "dayjs";

const isGoodTeam = (asiaHandicap = "") => {
  return asiaHandicap.includes("-");
};

const DisplayNumberCell = (data: CellContext<any, unknown>) => {
  const value = data.getValue<number>() || 0;
  return (
    <div className="text-right">
      <b
        className={classNames({
          "text-red-500": value < 0,
        })}
      >
        {Number(Number(value).toFixed(2)).toLocaleString("en-US")}
      </b>
    </div>
  );
};

const DisplayDateCell = (data: CellContext<any, unknown>) => {
  const createDate = data.getValue<string>();
  return (
    <div className="text-center text-xs">
      {dayjs(createDate).format("DD-MM-YYYY HH:mm:ss")}
    </div>
  );
};

export const COLUMNS: ColumnDef<any>[] = [
  {
    accessorKey: "no",
    header: "No",
    cell: (data) => {
      return (
        <div className="text-center">
          {((data as any)?.page - 1) * (data as any)?.limit +
            data.row.index +
            1}
        </div>
      );
    },
  },
  {
    accessorKey: "sport",
    header: "Matchs",
    cell: (data) => {
      const matches = data.getValue<any[]>();

      return (
        <>
          <div className="text-xs">
            Bet Id:{" "}
            <b className="text-blue-gray-600 italic">
              {data.row.original.betId}
            </b>
          </div>
          <div>
            <div className="flex text-xs bg-white">
              <div className="border-b py-1 font-bold border-black w-6 text-center">
                No.
              </div>
              <div className="border-b py-1 font-bold border-l border-black flex-1 text-center">
                Matches
              </div>
            </div>
            {matches?.map((match, index) => {
              let isHomeTeamGood = false;
              let isAwayTeamGood = false;

              if (match.value !== "0" || match.option === 3) {
                if (match.option === 0 && isGoodTeam(match.value)) {
                  isHomeTeamGood = true;
                }

                if (match.option === 0 && !isGoodTeam(match.value)) {
                  isAwayTeamGood = true;
                }

                if (match.option === 1 && isGoodTeam(match.value)) {
                  isAwayTeamGood = true;
                }

                if (match.option === 1 && !isGoodTeam(match.value)) {
                  isHomeTeamGood = true;
                }
              }
              return (
                <div
                  key={match._id}
                  className="odd:bg-blue-gray-100 even:bg-white"
                >
                  <div className="flex">
                    <div className="">
                      <div className="bg-yellow-200 mt-2 w-6 text-center">
                        {index + 1}.
                      </div>
                    </div>
                    <div className="text-xs border-l p-2 border-black flex-1">
                      <div
                        className={classNames(
                          "italic font-semibold bg-blue-50",
                          {
                            "text-red-500": isHomeTeamGood,
                          }
                        )}
                      >
                        {match.matchName.en.h}
                      </div>
                      <div
                        className={classNames(
                          "italic font-semibold bg-blue-50",
                          {
                            "text-red-500": isAwayTeamGood,
                          }
                        )}
                      >
                        {match.matchName.en.a}
                      </div>
                      {match.table === "live" && (
                        <div>
                          Score at that time: <b>[{match.score}]</b>
                        </div>
                      )}
                      <div>
                        Type:{" "}
                        <b
                          className={classNames({
                            "text-red-500": match.table === "live",
                            "text-green-800": match.table === "today",
                          })}
                        >
                          {match.table}{" "}
                          {match.table === "live" ? `(${match.info?.lt})` : ""}
                        </b>
                      </div>
                      <div>
                        Market:{" "}
                        <b>
                          {`${match.betType}`.toUpperCase()}{" "}
                          {match.betType !== "oe" && `[${match.value}]`}
                        </b>
                      </div>
                      <div>
                        Price:{" "}
                        <b
                          className={classNames({
                            "text-red-500": match.price < 0,
                          })}
                        >
                          {match.price}
                        </b>
                      </div>
                      <div className="flex">
                        <span>Bet side: </span>
                        <BettingTeamName
                          option={match.option}
                          isHomeGoodTeam={isHomeTeamGood}
                          isAwayGoodTeam={isAwayTeamGood}
                          home={match.matchName.en.h}
                          away={match.matchName.en.a}
                          betPriceType={match.betType}
                        />
                      </div>

                      <div>
                        Result:{" "}
                        <b
                          className={classNames({
                            "text-red-500": match.status?.includes("lose"),
                            "text-green-800": match.status?.includes("win"),
                            "text-blue-800": match.status === "draw",
                          })}
                        >
                          {match.status}{" "}
                          {match.status !== "running" &&
                            `HT [${match.result?.periodScores?.[0]?.homeScore}:${match.result?.periodScores?.[0]?.awayScore}] | FT [${match.result.homeScore}:${match.result.awayScore}]`}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    },
  },
  {
    accessorKey: "type",
    header: "Type",
    cell: (data) => {
      const type = data.getValue<string>();
      return <div className="text-center">{type}</div>;
    },
  },
  {
    accessorKey: "realBetAmt",
    header: "Real bet amount",
    cell: DisplayNumberCell,
  },
  {
    accessorKey: "betAmt",
    header: "Bet amount",
    cell: DisplayNumberCell,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (data) => {
      const status = data.getValue<string>();
      return <div className="text-center">{status}</div>;
    },
  },
  {
    accessorKey: "winLose",
    header: "Win lose",
    cell: DisplayNumberCell,
  },
  {
    accessorKey: "createDate",
    header: "Created at",
    cell: DisplayDateCell,
  },
  {
    accessorKey: "updateDate",
    header: "Updated at",
    cell: DisplayDateCell,
  },
  {
    accessorKey: "settleDate",
    header: "Settled at",
    cell: DisplayDateCell,
  },
  {
    accessorKey: "username",
    header: "By",
    cell: (data) => {
      const username = data.getValue<string>();
      return <div className="text-center">{username}</div>;
    },
  },
];
