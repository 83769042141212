import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import LogsTable from "../../Component/LogsTable";
import service from "../../service";

const TestAndLogs = () => {
  const [skip] = useState(0);
  const logs = useQuery({
    queryKey: ["logs", skip],
    queryFn: () => service.getLogs(skip),
  });

  return (
    <div className="overflow-x-auto">
      <LogsTable data={logs.data} />
    </div>
  );
};

export default TestAndLogs;
