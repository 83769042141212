import {
  redirect,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from "../Page/Login";
import Overview from "../Page/Overview";
import { useQueryClient } from "@tanstack/react-query";
import service from "../service";
import AppLayout from "../Component/AppLayout";
import PageLayout from "../Component/PageLayout";
import APIKey from "../Page/APIKey";
import Webhook from "../Page/Webhook";
import TestWebhook from "../Page/TestWebhook";
import Member from "../Page/Member";
import Ticket from "../Page/Ticket";
import Report from "../Page/Report";
import OrgChart from "../Page/Member/OrgChart";
import CreateForm from "../Page/Member/CreateForm";
import Logs from "../Page/Logs";

const Router = () => {
  const queryClient = useQueryClient();

  const loginLoader = async () => {
    try {
      const res = await service.getProfile();

      if (res.code === 0 && res.data) {
        queryClient.setQueryData(["profile"], res.data);
        return redirect("/profile");
      }

      queryClient.setQueryData(["profile"], null);
      window?.localStorage.removeItem("auth");
      return null;
    } catch (error) {
      queryClient.setQueryData(["profile"], null);
      window?.localStorage.removeItem("auth");
      return null;
    }
  };

  const loader = async () => {
    try {
      const profile = await service.getProfile();

      if (!profile) {
        queryClient.setQueryData(["profile"], null);

        return redirect("/login");
      }

      return profile;
    } catch (error) {
      queryClient.setQueryData(["profile"], null);
      window?.localStorage.removeItem("auth");
      return redirect("/login");
    }
  };

  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
      loader: loginLoader,
    },
    {
      path: "/",
      element: <AppLayout />,
      loader: loader,
      children: [
        {
          index: true,
          element: (
            <PageLayout title="Overview">
              <Overview />
            </PageLayout>
          ),
        },
        {
          path: "member",
          element: <Member />,
          children: [
            {
              index: true,
              element: <OrgChart />,
            },
            {
              path: "create",
              element: <CreateForm />,
            },
          ],
        },
        {
          path: "ticket",
          element: (
            <PageLayout title="Ticket">
              <Ticket />
            </PageLayout>
          ),
        },
        {
          path: "report",
          element: (
            <PageLayout title="Report">
              <Report />
            </PageLayout>
          ),
        },
        {
          path: "webhook",
          element: (
            <div className="flex flex-col w-full">
              <PageLayout title="API Key">
                <APIKey />
              </PageLayout>
              <PageLayout title="Webhoook">
                <Webhook />
              </PageLayout>
            </div>
          ),
        },
        {
          path: "test",
          element: (
            <div className="flex flex-col w-full">
              <PageLayout title="Test Webhook">
                <TestWebhook />
              </PageLayout>
            </div>
          ),
        },
        {
          path: "logs",
          element: (
            <div className="flex flex-col w-full">
              <PageLayout title="Logs">
                <Logs />
              </PageLayout>
            </div>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
