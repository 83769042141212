import { StyledTable } from "./styled";
import Table from "../Table";
import { COLUMNS } from "./config";
import { FC, ReactElement } from "react";

const TicketTable: FC<{
  data: any[];
  context?: Record<string, any>;
  Footer?: ReactElement;
}> = ({ data = [], context, Footer }) => {
  return (
    <StyledTable>
      <Table<any>
        data={data}
        columns={COLUMNS}
        context={context}
        Footer={Footer}
        placeholder="Your tickets have not been found."
      />
    </StyledTable>
  );
};

export default TicketTable;
