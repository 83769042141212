import { useQuery } from "@tanstack/react-query";
import classnames from "classnames";
import service from "../../service";
import dayjs from "dayjs";
import { Typography, Card, CardBody } from "@material-tailwind/react";
import AnimatedNumbers from "react-animated-numbers";
import Datepicker from "react-tailwindcss-datepicker";
import Loading from "../../Component/Loading";
import ShareTable from "../../Component/ShareTable";
import useDatePicker from "../../hook/useDatePicker";

const ANIMATE_CONFIG = [
  { mass: 1, tension: 220, friction: 100 },
  { mass: 1, tension: 180, friction: 130 },
  { mass: 1, tension: 280, friction: 90 },
  { mass: 1, tension: 180, friction: 135 },
  { mass: 1, tension: 260, friction: 100 },
  { mass: 1, tension: 210, friction: 180 },
];

const Overview = () => {
  const { value, handleChange } = useDatePicker();

  const overview = useQuery({
    queryFn: () =>
      service.getOverview({
        startDate: value.startDate.toISOString(),
        endDate: value.endDate.toISOString(),
      }),
    queryKey: [
      "overview",
      dayjs(value.startDate).format("DD-MM-YYYY"),
      dayjs(value.endDate).format("DD-MM-YYYY"),
    ],
  });

  const totalProfit =
    Number(overview.data?.totalBet || 0) -
    Number(overview.data?.totalPayout || 0) +
    Number(overview.data?.transaction?.total?.all || 0);

  return (
    <div className="flex flex-col gap-5">
      <Card className="">
        <CardBody>
          <div className="">
            <Typography variant="h4">Result</Typography>
            <Datepicker
              primaryColor="green"
              value={value}
              onChange={handleChange}
              showShortcuts={true}
              separator="to"
              displayFormat={"DD-MM-YYYY"}
              maxDate={dayjs().endOf("month").toDate()}
            />
          </div>

          <div className="py-5">
            {overview.isLoading ? (
              <Loading size={30} className="mx-auto" />
            ) : (
              <div className="flex gap-x-3 items-end justify-between ">
                <Typography variant="h5">Total bet amount: </Typography>
                <div className="flex items-end gap-x-3 flex-1 border-b-2 justify-end">
                  <AnimatedNumbers
                    includeComma
                    animateToNumber={overview.data?.totalBet || 0}
                    fontStyle={{ fontSize: 30 }}
                    locale="en-US"
                    configs={ANIMATE_CONFIG}
                  />

                  <Typography variant="h4">THB</Typography>
                </div>
              </div>
            )}
          </div>

          <div className="py-5">
            {overview.isLoading ? (
              <Loading size={30} className="mx-auto" />
            ) : (
              <div className="flex gap-x-3 items-end justify-between">
                <Typography variant="h5">Total payout amount: </Typography>
                <div className="flex items-end gap-x-3 flex-1 border-b-2 justify-end text-red-700">
                  <AnimatedNumbers
                    includeComma
                    animateToNumber={-overview.data?.totalPayout || 0}
                    fontStyle={{ fontSize: 30 }}
                    locale="en-US"
                    configs={ANIMATE_CONFIG}
                  />

                  <Typography variant="h4">THB</Typography>
                </div>
              </div>
            )}
          </div>

          <div className="py-5">
            <Typography variant="h5" className="mb-1">
              Share
            </Typography>
            {overview.isLoading ? (
              <Loading size={30} className="mx-auto" />
            ) : (
              <ShareTable
                data={overview.data?.transaction?.data}
                total={overview.data?.transaction?.total?.all}
              />
            )}
          </div>

          <div className="pb-5 pt-20">
            {overview.isLoading ? (
              <Loading size={30} className="mx-auto" />
            ) : (
              <div className="flex gap-x-3 items-end justify-between">
                <Typography variant="h5">Total Profit: </Typography>
                <div className="flex items-end gap-x-3 flex-1 border-b-2 justify-end">
                  <Typography
                    variant="h4"
                    className={classnames({
                      "text-red-700": totalProfit < 0,
                      "text-green-700": totalProfit > 0,
                    })}
                  >
                    {totalProfit > 0 && "+"}
                    {Number(totalProfit.toFixed(2)).toLocaleString("en-US")}
                  </Typography>
                  <Typography variant="h4">THB</Typography>
                </div>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Overview;
