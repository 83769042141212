// import { Button } from "@material-tailwind/react";
import PageLayout from "../../Component/PageLayout";
import {
  Outlet,
  useLocation,
  // useNavigate
} from "react-router-dom";

const Member = () => {
  //   const navigate = useNavigate();
  const location = useLocation();
  const isOnCreatePage = location.pathname === "/member/create";

  return (
    <PageLayout
      title={isOnCreatePage ? "Create Member" : "Organization"}
      //   ActionButton={
      //     <Button
      //       type="button"
      //       color={isOnCreatePage ? "red" : "green"}
      //       onClick={() => {
      //         if (isOnCreatePage) {
      //           navigate("/member");
      //         } else {
      //           navigate("/member/create");
      //         }
      //       }}
      //     >
      //       {isOnCreatePage ? "Go Back" : "Create"}
      //     </Button>
      //   }
    >
      <div className="h-full flex flex-col">
        <Outlet />
      </div>
    </PageLayout>
  );
};

export default Member;
