import { FC } from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import Navbar from "../../Container/Navbar";
import Sidebar from "../../Container/Sidebar";

export type Profile = {
  nickname: string;
};

const AppLayout: FC = () => {
  const data = useLoaderData() as Profile;

  return (
    <div className="flex flex-col bg-gray-50">
      <Navbar name={data.nickname} />
      <Sidebar />
      <div className="flex container mx-auto">
        <div className="flex-1 overflow-x-auto bg-gray-200 ml-72 pt-16 min-h-screen">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
