import styled from "styled-components";

export const StyledTable = styled.div`
  & th {
    background-color: gray;
    color: white;
    font-size: 14px;
    border: 1px solid gray;
    padding: 16px;
  }

  & td {
    border: 1px solid gray;
    padding: 8px;
    font-size: 14px;
    white-space: pre-wrap;
  }

  & tr:nth-child(even) {
    background-color: #e8e8e8;
  }

  & tr:nth-child(odd) {
    background-color: white;
  }
`;
