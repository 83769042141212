import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardBody,
} from "@material-tailwind/react";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";

import Loading from "../../Component/Loading";
import service from "../../service";

const APIKey = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const queryClient = useQueryClient();
  const createKey = useMutation({
    mutationFn: service.createKey,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });
  const profile = useQuery({
    queryKey: ["profile"],
    queryFn: service.getProfile,
  });

  const hasKey = !!profile?.data?.auto?.key;
  const key = profile?.data?.auto?.key;
  const isLoading = createKey.isLoading || profile.isLoading;

  return (
    <>
      <Card>
        <CardBody>
          {hasKey && (
            <div className="flex items-center gap-x-2 mb-5">
              <Typography variant="h6" className="bg-gray-300 p-2 rounded">
                {key}
              </Typography>
              <CopyToClipboard
                text={key}
                onCopy={() => {
                  alert("Already copied");
                }}
              >
                <IconButton variant="outlined">
                  <ClipboardDocumentIcon className="h-5 w-5" />
                </IconButton>
              </CopyToClipboard>
            </div>
          )}

          <Button
            onClick={handleClick}
            color="green"
            className="flex items-center py-2 px-4 gap-x-1 h-10"
            disabled={isLoading}
          >
            {isLoading && <Loading size={28} />}
            {hasKey ? "สร้างใหม่" : "สร้าง"}
          </Button>
        </CardBody>
      </Card>
      <Dialog open={isOpen} size="xs" handler={handleClick}>
        <DialogHeader>
          คุณต้องการที่จะสร้าง API Key ใหม่ใช่หรือไม่?
        </DialogHeader>
        <DialogBody divider>
           *** ถ้า API Key ใหม่ถูกสร้าง API Key อันเก่าจะใช้ไม่ได้
        </DialogBody>
        <DialogFooter className="flex justify-center">
          <Button
            color="green"
            onClick={() => {
              createKey.mutate();
              handleClick();
            }}
            className="mr-1"
          >
            สร้าง API Key ใหม่
          </Button>
          <Button color="red" onClick={handleClick}>
            ออกจากหน้านี้
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default APIKey;
