import {
  Navbar as MuiNavbar,
  Typography,
  Avatar,
} from "@material-tailwind/react";

import { FC } from "react";

export type NavbarProps = {
  name: string;
};

const Navbar: FC<NavbarProps> = ({ name }) => {
  return (
    <MuiNavbar className="py-2 px-4 max-w-full lg:px-8 lg:py-4 rounded-none bg-gray-900 border-0 fixed top-0 z-[100]">
      <div className="container mx-auto flex items-center justify-between">
        <Typography variant="h5" className="flex items-center">
          Sportbook
          <img
            src="/logo192.png"
            className="ml-2 mr-0.5"
            alt=""
            height={35}
            width={35}
          />
          ackoffice
        </Typography>

        <div className="flex items-center gap-x-2">
          <Avatar
            src={`https://ui-avatars.com/api/?background=065c15&color=fff&name=${name}`}
            size="sm"
          />
          <Typography variant="h6">{name}</Typography>
        </div>
      </div>
    </MuiNavbar>
  );
};

export default Navbar;
