import { StyledTable } from "./styled";
import Table from "../Table";
import { COLUMNS } from "../../Component/WinloseTable/config";
import { FC, ReactElement } from "react";

const WinloseTable: FC<{
  data: any[];
  context?: Record<string, any>;
  Footer?: ReactElement;
  isLoading?: boolean;
}> = ({ data = [], context, Footer, isLoading = false }) => {
  return (
    <StyledTable>
      <Table<any>
        isLoading={isLoading}
        data={data}
        columns={COLUMNS}
        context={context}
        Footer={Footer}
      />
    </StyledTable>
  );
};

export default WinloseTable;
