import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useFocusUser = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<string>(searchParams.get("username") || "");

  useEffect(() => {
    setData(searchParams.get("username") || "");
  }, [searchParams]);

  const onChange = useCallback((username: string) => {
    setData(username);
  }, []);

  return {
    onChange,
    data,
  };
};

export default useFocusUser;
